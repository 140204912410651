import { useTheme, Grid, View, Button, Message } from "@aws-amplify/ui-react";
import { ThemeProvider as MtThemeProvider, createTheme } from "@mui/material";

import axios, { AxiosError } from "axios";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { tableIcons } from "../components/Icons";
import LeftMenu from "../components/LeftMenu";
import * as Constant from "../utils/constants";
import * as StringUtil from "../utils/string_util";
import "@aws-amplify/ui-react/styles.css";

const AppUsers: React.FC = () => {
  const { tokens } = useTheme();
  const defaultMaterialTheme = createTheme({});

  const [isLoading, setIsLoading] = useState(true);
  const [appUsers, setAppUsers] = useState([]);
  const [errorMessage, setErrroMessage] = useState<string | null>(null);

  useEffect(() => {
    const urlGet = StringUtil.formatString(Constant.API_URL_APPUSERS, StringUtil.apiUrl());
    axios
      .get(urlGet, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((response) => {
        const resData = response.data;
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const data = resData.map((obj: any) => ({
          ...obj,
          KAKAPO_Auth: StringUtil.convertStringForDisplay(obj.KAKAPO_Auth),
          Kiwi_Auth: StringUtil.convertStringForDisplay(obj.Kiwi_Auth),
          "KAKAPO-DL_Auth": StringUtil.convertStringForDisplay(obj["KAKAPO-DL_Auth"]),
        }));
        setAppUsers(JSON.parse(JSON.stringify(data)));
        setIsLoading(false);
      })
      .catch((error: AxiosError) => {
        setErrroMessage(error.message);
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      <LeftMenu title="App users">
        {errorMessage === null && (
          <>
            <Grid justifyContent="flex-end">
              <ReactRouterLink to="/upload">
                <Button variation="primary">Upload</Button>
              </ReactRouterLink>
            </Grid>
            <View className="table-base">
              <MtThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                  icons={tableIcons}
                  columns={[
                    {
                      title: "Email",
                      field: "email",
                      cellStyle: { whiteSpace: "nowrap", minWidth: 200 },
                    },
                    {
                      title: "Project",
                      field: "project",
                      cellStyle: { whiteSpace: "nowrap", minWidth: 150 },
                    },
                    {
                      title: "KAKAPO_Auth",
                      field: "KAKAPO_Auth",
                      cellStyle: { whiteSpace: "pre-line", minWidth: 170 },
                    },
                    {
                      title: "Kiwi_Auth",
                      field: "Kiwi_Auth",
                      cellStyle: { whiteSpace: "pre-line", minWidth: 170 },
                    },
                    {
                      title: "Paragon_Auth",
                      field: "Paragon_Auth",
                      cellStyle: { whiteSpace: "pre-line", minWidth: 170 },
                    },
                    {
                      title: "KAKAPO-DL_Auth",
                      field: "KAKAPO-DL_Auth",
                      cellStyle: { whiteSpace: "pre-line", minWidth: 200 },
                    },
                    {
                      title: "CreatedAt",
                      field: "createdAt",
                      cellStyle: { whiteSpace: "nowrap", minWidth: 150 },
                    },
                    {
                      title: "UpdatedAt",
                      field: "updatedAt",
                      cellStyle: { whiteSpace: "nowrap", minWidth: 150 },
                    },
                    {
                      title: "LastAccess",
                      field: "lastAccess",
                      cellStyle: { whiteSpace: "nowrap", minWidth: 150 },
                    },
                  ]}
                  data={appUsers}
                  isLoading={isLoading}
                  options={{
                    showTitle: false,
                    toolbar: false,
                    search: false,
                    filtering: true,
                    sorting: true,
                    maxBodyHeight: "700px",
                    headerStyle: { position: "sticky", top: 0 },
                  }}
                />
              </MtThemeProvider>
            </View>
          </>
        )}
        {errorMessage !== null && (
          <View margin={tokens.space.large}>
            <Message colorTheme="error" isDismissible={false}>
              {errorMessage}
            </Message>
          </View>
        )}
      </LeftMenu>
    </div>
  );
};

export default AppUsers;
