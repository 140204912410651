import "@aws-amplify/ui-react/styles.css";
import { Authenticator, useTheme, View } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { fetchUserAttributes, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import aws_exports from "./aws-exports";
import AppUsers from "./pages/AppUsers";
import Completed from "./pages/Completed";
import ErrorComponent, { ErrorProps } from "./pages/Error";
import Upload from "./pages/Upload";
import * as Constant from "./utils/constants";
import { dateJst } from "./utils/date_util";
import * as StringUtil from "./utils/string_util";

Amplify.configure(aws_exports);

const App = () => {
  const pathname = location.pathname;
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [event, setEvent] = useState<string>();
  const [errorInfo, setErrorInfo] = useState<ErrorProps | null>(null);
  const navigate = useNavigate();
  const redirectUrl = StringUtil.formatString(
    Constant.COGNITO_URL,
    process.env.REACT_APP_CUSTOM_DOMAIN ?? "",
    process.env.REACT_APP_CLIENT_ID ?? "",
    StringUtil.formatString(Constant.REDIRECT_URI, process.env.REACT_APP_CUSTOM_DOMAIN ?? "")
  );

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signedIn":
          setEvent("signedIn");
          adminCheck();
          break;
        case "signedOut":
          setIsAdmin(false);
          break;
        default:
      }
    });
    return unsubscribe;
  }, []);

  const adminCheck = async () => {
    const currentUserAttributes = await fetchUserAttributes();
    const email = currentUserAttributes.email;

    const body = {
      email: email,
      lastAccess: dateJst(),
    };
    try {
      const urlGet = StringUtil.formatString(Constant.API_URL_ADMINS_GET, StringUtil.apiUrl(), email ?? "");
      const urlPut = StringUtil.formatString(Constant.API_URL_ADMINS, StringUtil.apiUrl());
      await axios.get(urlGet, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      });
      await axios.put(urlPut, body, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      });
      setIsAdmin(true);

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      setIsAdmin(false);
      let errInfo: ErrorProps;
      if (error instanceof AxiosError) {
        errInfo = {
          status: error.response?.status,
          code: error.code,
          message: error.message,
        };
      } else {
        errInfo = {
          code: error.name,
          message: error.message,
        };
      }
      setErrorInfo(errInfo);
      signOut();
      navigate("/error");
    }
  };

  const components = {
    SignIn: {
      Footer() {
        const { tokens } = useTheme();
        return <View padding={`0 ${tokens.space.xl} ${tokens.space.xl} ${tokens.space.xl}`} />;
      },
    },
  };

  return (
    <div>
      {pathname === "/redirect" && (
        <Routes>
          <Route
            path="/redirect"
            Component={() => {
              window.location.href = redirectUrl ?? "";
              return null;
            }}
          />
        </Routes>
      )}
      {pathname === "/completed" && (
        <Routes>
          <Route path="/completed" element={<Completed />} />
        </Routes>
      )}
      {pathname === "/error" && (
        <Routes>
          <Route
            path="/error"
            element={<ErrorComponent code={errorInfo?.code} status={errorInfo?.status} message={errorInfo?.message} />}
          />
        </Routes>
      )}
      {!["/redirect", "/completed", "/error"].includes(pathname) && (
        <Authenticator hideSignUp={true} components={components}>
          <Routes>
            {(isAdmin || (isAdmin === null && event !== "signedIn")) && (
              <>
                <Route path="/" element={<AppUsers />} />
                <Route path="/appusers" element={<AppUsers />} />
                <Route path="/upload" element={<Upload />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
        </Authenticator>
      )}
    </div>
  );
};

export default () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
