import { useTheme } from "@aws-amplify/ui-react";
import { Grid, View, Flex } from "@aws-amplify/ui-react";

const Completed = () => {
  const { tokens } = useTheme();
  document.title = "Signin";
  document.body.style.background = "#909090";
  return (
    <Grid justifyContent={"center"}>
      <View
        boxShadow="0px 2px 6px var(--amplify-colors-overlay-90)"
        backgroundColor="var(--amplify-colors-white)"
        margin="30px"
        width="350px"
        maxWidth="100%"
      >
        <Flex direction="column" gap="1rem">
          <View padding={"25px 0"} backgroundColor="lightgray" />
          <View padding="var(--amplify-space-medium)">
            <View fontSize={tokens.fontSizes.xxl}>Completed</View>
            <View paddingTop={tokens.space.medium}>The authentication was successful.</View>
          </View>
        </Flex>
      </View>
    </Grid>
  );
};

export default Completed;
