import * as Constant from "../utils/constants";

export const formatString = (str: string, ...args: string[]): string => {
  for (const [i, arg] of args.entries()) {
    const regExp = new RegExp(`\\{${i}\\}`, "g");
    str = str.replace(regExp, arg as string);
  }
  return str;
};

export const apiUrl = (): string => {
  return formatString(Constant.API_URL, process.env.REACT_APP_CUSTOM_DOMAIN ?? "");
};

export const replaceStringAll = (source: string | undefined, pattern: string, replacement: string): string => {
  if (source === undefined) return "";
  return source.replaceAll(pattern, replacement);
};

export const convertStringForDisplay = (source: string | undefined): string => {
  if (source === undefined) return "";
  try {
    const data: string[] = [];
    const json = JSON.parse(source);
    for (const [key, value] of Object.entries(json)) {
      data.push(`${key}: ${value}`);
    }
    return data.join("\n");
  } catch {
    return replaceStringAll(source, ",", "\n");
  }
};
