import { useTheme, View, Flex, Button, Message } from "@aws-amplify/ui-react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CircularProgress from "@mui/material/CircularProgress";
import axios, { AxiosError } from "axios";
import { MuiFileInput } from "mui-file-input";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LeftMenu from "../components/LeftMenu";
import * as Constant from "../utils/constants";
import * as StringUtil from "../utils/string_util";

import "@aws-amplify/ui-react/styles.css";
import "../styles/styles.css";

const AddUsers = () => {
  const { tokens } = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onChangeFile = (file: File | null) => {
    setFile(file);
    setDisabled(!file);
  };

  const onClickSubmit = async () => {
    if (!file) return;
    setMessage(null);
    setDisabled(true);

    const formData = new FormData();
    formData.append("file", file);

    setIsLoading(true);
    const urlUpload = StringUtil.formatString(Constant.API_URL_UPLOAD, StringUtil.apiUrl(), file.name);
    axios
      .post(urlUpload, formData, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then(() => {
        navigate("/appusers");
      })
      .catch((e: AxiosError) => {
        setIsLoading(false);
        setDisabled(!file);
        setMessage(e.message);
      });
  };

  return (
    <View>
      <LeftMenu title="Upload">
        <View className="file-field">
          <View marginBottom={tokens.space.large} hidden={message === null}>
            <Message colorTheme="error" isDismissible={true}>
              {message}
            </Message>
          </View>
          <MuiFileInput
            value={file}
            onChange={onChangeFile}
            InputProps={{ startAdornment: <AttachFileIcon /> }}
            fullWidth={true}
            hideSizeText={true}
            variant="outlined"
          />
          <Flex direction="row" justifyContent="flex-end" wrap="nowrap" gap="3rem" marginTop={tokens.space.xxl}>
            {isLoading && <CircularProgress />}
            <Link to="/appusers">
              <Button className="button-base">Cancel</Button>
            </Link>
            <Button
              className="button-base"
              name="btnUpload"
              variation="primary"
              disabled={disabled}
              onClick={onClickSubmit}
            >
              Upload
            </Button>
          </Flex>
        </View>
      </LeftMenu>
    </View>
  );
};

export default AddUsers;
