import { useTheme } from "@aws-amplify/ui-react";
import { Grid, View, Flex, Divider, Button, Alert } from "@aws-amplify/ui-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface ErrorProps {
  status?: number | null;
  code?: string | null;
  message?: string | null;
}

const Error: React.FC<ErrorProps> = (props) => {
  const { tokens } = useTheme();
  const navigate = useNavigate();
  const singOutClick = () => {
    navigate("/");
  };

  return (
    <Grid justifyContent={"center"}>
      <View
        boxShadow="0px 2px 6px var(--amplify-colors-shadow-secondary)"
        borderWidth="width	var(--amplify-border-widths-small)"
        backgroundColor="var(--amplify-colors-white)"
        border="1px solid var(--amplify-colors-black)"
        padding="var(--amplify-space-xl)"
        width="30rem"
        maxWidth="100%"
      >
        <Flex direction="column" gap="1rem">
          <Alert
            variation="error"
            isDismissible={false}
            hasIcon={true}
            backgroundColor={tokens.colors.white}
            padding="0"
          >
            <View fontSize={tokens.fontSizes.xl}>Error:{props.status}</View>
          </Alert>
          <Divider orientation="horizontal" size="small" />
          <View>{props.code}</View>
          <View>{props.message}</View>
          <Button variation="link" fontWeight={tokens.fontWeights.normal} onClick={singOutClick}>
            Back to Sign In
          </Button>
        </Flex>
      </View>
    </Grid>
  );
};

export default Error;
