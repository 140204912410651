import { useTheme, Theme, ThemeProvider, Grid, View, Flex, Divider, Text, Button } from "@aws-amplify/ui-react";
import { signOut } from "aws-amplify/auth";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "../styles/styles.css";

export interface Menu {
  title: React.ReactNode;
  children: React.ReactNode;
}

const theme: Theme = {
  name: "menu-theme",
  tokens: {
    components: {
      divider: {
        borderWidth: { value: "{borderWidths.small}" },
      },
    },
  },
};

const LeftMenu: React.FC<Menu> = ({ title, children }) => {
  const { tokens } = useTheme();
  const navigate = useNavigate();
  const singOutClick = () => {
    signOut();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid templateColumns="15rem 2rem auto 3rem" templateRows="3rem 1fr" autoRows={"max-content"}>
          <View columnStart="1" columnEnd="2" rowStart="1" rowEnd="2" />
          <View columnStart="1" columnEnd="2" rowStart="2" rowEnd="-1" paddingLeft={tokens.space.large}>
            <Text variation="primary" margin={tokens.space.small} fontWeight={tokens.fontWeights.medium}>
              User list
            </Text>
            <Flex direction="column" justifyContent="flex-start" wrap="nowrap">
              <Link to="/appusers">
                <Button variation="link" fontWeight={tokens.fontWeights.normal}>
                  App users
                </Button>
              </Link>
              <View>
                <Button
                  variation="link"
                  fontWeight={tokens.fontWeights.normal}
                  marginTop={tokens.space.small}
                  onClick={singOutClick}
                >
                  Sign out
                </Button>
              </View>
            </Flex>
          </View>
          <Divider
            className="border-field"
            columnStart="2"
            columnEnd="3"
            rowStart="1"
            rowEnd="-1"
            orientation="vertical"
            margin={tokens.space.small}
          />
          <View columnStart="3" columnEnd="4" rowStart="2" rowEnd="-1" paddingLeft={tokens.space.large}>
            <Text fontSize={tokens.fontSizes.xl} fontWeight={tokens.fontWeights.bold}>
              {title}
            </Text>
            {children}
          </View>
          <View columnStart="4" columnEnd="-1" rowStart="1" rowEnd="-1"></View>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default LeftMenu;
